<template>
  <div v-if="hasPermission('access_cases')">
    <b-card>
      <show
        v-if="patient_case"
        @refresh="init"
        :item="patient_case"
        :load="load"
        :isVertical="false"
        @changeTab="move"
      >
        <template #add_tab>
          <div class="row my-3">
            <div class="col d-flex justify-content-around">
              <b-badge
                v-if="patient_case && patient_case.auditor"
                pill
                :variant="`light-${getStatus(5).color}`"
                class="text-capitalize"
              >
                {{ patient_case.auditor.name }}
              </b-badge>

              <div v-if="patient_case.patient_type == 'OUTPATIENT'">
                <b-badge
                  v-if="patient_case && patient_case.patient_type"
                  pill
                  :variant="`light-${getStatus(2).color}`"
                  class="text-capitalize"
                >
                  Outpatient
                </b-badge>
              </div>

              <div v-if="patient_case.patient_type == 'INPATIENT'">
                <b-badge
                  v-if="patient_case && patient_case.patient_type"
                  pill
                  :variant="`light-${getStatus(3).color}`"
                  class="text-capitalize"
                >
                  Inpatient
                </b-badge>
              </div>
              <div>
                <b-badge
                  v-if="patient_case && patient_case.status"
                  pill
                  :variant="`light-${
                    getStatus(patient_case.status.status).color
                  }`"
                  class="text-capitalize"
                >
                  {{ patient_case.status.name }}
                </b-badge>
              </div>

              <div>
                <b-badge
                  v-if="patient_case && patient_case.assign_date"
                  pill
                  :variant="`light-${getStatus(6).color}`"
                  class="text-capitalize"
                >
                  Assign Date: {{ patient_case.assign_date }}
                </b-badge>
              </div>
            </div>
          </div>

          <b-tab
            title="Transaction History"
            :title-item-class="'tab-title-class'"
          >
            <template #title>
              <feather-icon icon="UserIcon" />
              <span>Medical Basic Information</span>
            </template>

            <list
              :data="patient_case"
              :loading="load"
              :only="[
                'account_no',
                'birth_date',
                'gender',
                'nationality',
                'patient_type',
                'financial_category_code',
                'financial_category',
                'financial_category_number',
                'medical_record',
                'ifa',
                'dr_name',
                'branch_name',
                'branch_code',
                'admission_date',
              ]"
            ></list>

            <strong class="ml-2">Charge Items</strong>
            <b-table
              :items="patient_case.charge_items"
              :fields="charge_items_fields"
              striped
            >
              <template #cell(date)="data">
                {{ data.item.date }}
              </template>
            </b-table>
          </b-tab>

          <!-- branch name , code , chg item , surgery -->

          <b-tab>
            <template #title>
              <feather-icon icon="CheckSquareIcon" />
              <span>Audit View</span>
            </template>
            <validation-observer ref="simpleRules">
              <b-row class="mb-5 border py-2 card-shadow">
                <b-col md="8">
                  <h5 class="mb-1">Treatment Plan</h5>

                  <b-table
                    bordered
                    :items="patient_case.treatment_plan"
                    :fields="planfields"
                  ></b-table>
                </b-col>

                <b-col md="4">
                  <b-row>
                    <b-col md="12">
                      <h6 class="mb-1">Review status</h6>
                      <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="treatmentPlanStatus"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.treatment_plan_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>

                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <h6>Add Custom Comment</h6>
                      <b-form-textarea v-model="case_review.treatment_plan">
                      </b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row
                class="mb-5 border py-2 card-shadow"
                v-if="patient_case.medical_type == 1"
              >
                <b-col md="8">
                  <h6 class="mb-1">Operative Procedures</h6>
                  <b-table
                    class="text-lg-left"
                    :items="patient_case.operative_procedures"
                    :fields="operative_procedures_fields"
                    bordered
                  ></b-table>
                </b-col>

                <b-col md="4">
                  <b-row class="mb-2">
                    <b-col md="12">
                      <h6 class="mb-1">Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="operativeProcedures"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.operative_procedure_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <h6>Add Custom Comment</h6>
                        <b-form-textarea
                          v-model="case_review.operative_procedure"
                        >
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row
                class="mb-5 border py-2 card-shadow"
                v-if="patient_case.medical_type == 2"
              >
                <!-- <b-col md="8">
                <h6 class="mb-1">Attending Notes</h6>
                <b-table responsive class="text-lg-left" bordered :items="patient_case.attending_notes"
                  :fields="attending_fields"></b-table>
              </b-col> -->
                <b-col md="8">
                  <h6 class="mb-1">Attending Notes</h6>
                  <b-table
                    responsive
                    class="text-lg-left"
                    bordered
                    :items="patient_case.attending_notes"
                    :fields="attending_fields"
                  >
                    <template #cell(description)="row">
                      {{ row.value }}
                    </template>
                  </b-table>
                </b-col>
                <b-col md="4">
                  <b-row class="mb-2">
                    <b-col md="12">
                      <h6 class="mb-1">Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="AttendingNote"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.attending_note_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group>
                        <h6>Add Comment</h6>
                        <b-form-textarea
                          v-model="case_review.attending_note"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row
                class="mb-5 border py-2 card-shadow"
                v-if="patient_case.medical_type == 2"
              >
                <b-col md="8">
                  <h6 class="mb-1">Investigation</h6>
                  <b-table
                    :items="patient_case.investigation"
                    class="text-lg-left"
                    bordered
                    :fields="investigation_fields"
                  ></b-table>
                </b-col>

                <b-col md="4">
                  <b-row class="mb-2">
                    <b-col md="12">
                      <h6 class="mb-1">Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="investigations"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.investigations_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group>
                        <h6>Add Comment</h6>
                        <b-form-textarea
                          v-model="case_review.investigations"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mb-5 border py-2 card-shadow">
                <b-col md="8">
                  <h6 class="mb-1">Diagnosis</h6>
                  <b-table
                    :items="patient_case.diagnosis"
                    class="text-lg-left"
                    :fields="diagnosis_fields"
                    bordered
                  ></b-table>
                </b-col>

                <b-col md="4">
                  <b-row class="mb-2">
                    <b-col md="12">
                      <h6 class="mb-1">Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Diagnosis"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.diagnosis_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <h6>Add Custom Comment</h6>
                        <b-form-textarea
                          v-model="case_review.diagnosis"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mb-5 border py-2 card-shadow">
                <b-col md="8">
                  <h6 class="mb-1">Chief Complaint</h6>
                  <b-table
                    class="text-lg-left"
                    :items="patient_case.chief_complaint"
                    :fields="chief_fields"
                    bordered
                  >
                    <template #cell(description)="data">
                      <p
                        v-if="
                          data.item.description && data.item.description[0].Data
                        "
                      >
                        {{ data.item.description[0].Data }}
                      </p>
                      <p v-else>{{ data.item.description }}</p>
                    </template>
                    <!-- <template #cell(description)="data">
                    <p
                      v-if="
                        data.item.description && data.item.description[0].Data
                      "
                    >
                      {{ data.item.description[0].Data }}
                    </p>
                    <p v-else>{{ data.item.description }}</p>
                  </template> -->
                  </b-table>
                </b-col>

                <b-col md="4">
                  <b-row class="mb-2">
                    <b-col md="12">
                      <h6 class="mb-1">Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="chiefComplaint"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.chief_complaint_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group>
                        <h6>Add Custom Comment</h6>
                        <b-form-textarea
                          v-model="case_review.chief_complaint"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="mb-5 border py-2 card-shadow">
                <b-col md="8">
                  <h5 class="mb-1">Vital Signs</h5>

                  <b-table
                    bordered
                    :items="patient_case.vital_signs"
                    :fields="vitalfields"
                  ></b-table>
                </b-col>

                <b-col md="4">
                  <b-row>
                    <b-col md="12">
                      <h6 class="mb-1">Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="vitalSigns"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.vital_signs_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <h6>Add Custom Comment</h6>

                      <b-form-textarea v-model="case_review.vital_signs">
                      </b-form-textarea>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>

              <b-row class="">
                <b-col md="4">
                  <b-row class="mb-2">
                    <b-col md="12">
                      <h6 class="mb-1">Consent Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Consent"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.consent_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <h6>Add Custom Comment</h6>
                        <b-form-textarea
                          v-model="case_review.consent"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="4">
                  <b-row class="mb-2">
                    <b-col md="12">
                      <h6 class="mb-1">Discharge Summary Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="Discharge"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.discharge_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <h6>Add Custom Comment</h6>
                        <b-form-textarea
                          v-model="case_review.discharge"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="4">
                  <b-row class="mb-2">
                    <b-col md="12">
                      <h6 class="mb-1">Operative Report Review status</h6>
                      <b-form-group>
                        <validation-provider
                        #default="{ errors }"
                        name="operativeReport"
                        rules="required"
                      >
                        <v-select
                          :options="review_status"
                          v-model="case_review.operative_report_type"
                          :reduce="(val) => val.id"
                        >
                          <template v-slot:option="option">
                            {{ option.type }}
                          </template>
                          <template #selected-option="{ type }">
                            {{ type }}
                          </template>
                          <template #no-options>
                            {{ $t("noMatching") }}
                          </template>
                        </v-select>
                        <ValidationErrors :frontend-errors="errors" />
                  </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group>
                        <h6>Add Custom Comment</h6>
                        <b-form-textarea
                          v-model="case_review.operative_report"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" class="mt-2">
                  <b-form-group>
                    <h6>Notes</h6>
                    <b-form-textarea
                      v-model="case_review.notes"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </validation-observer>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="CheckSquareIcon" />
              <span> Case History</span>
            </template>

            <b-card title="History">
              <b-timeline
                :items="timelineItems"
                :reverse="false"
                date-format="dd/MM/yy HH:mm:ss"
                variant="primary"
                :human-friendly-time="true"
              />
            </b-card>
          </b-tab>

          <b-tab>
            <template #title>
              <feather-icon icon="CheckSquareIcon" />
              <span> Approval Tracking </span>
            </template>
            <strong class="ml-2 mb-2"> Case Approvals</strong>
            <b-table
              class="mt-1"
              :items="patient_case.approvals"
              :fields="case_approvals_fields"
              striped
            >
              <template #cell(date)="data">
                {{ data.item.date }}
              </template>
            </b-table>
          </b-tab>
        </template>
      </show>
    </b-card>
    <b-row class="mb-5">
      <b-col md="12" class="d-flex justify-content-between">
        <div v-if="status == 0 || status == 2">
          <b-button class="btn btn-info mr-2" @click="SaveReview">
            Save
          </b-button>
          <router-link :to="`/cases`">
            <b-button class="mr-5"> Cancel </b-button>
          </router-link>
        </div>

        <div v-if="status == 0 || status == 2">
          <b-button class="btn btn-danger mr-2" @click="changeStatus(1)">
            Return to Branch
          </b-button>
          <b-button class="btn btn-success mr-2" @click="changeStatus(3)">
            Close Case
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import show from "@/views/cases/components/show";
import {
  BTab,
  BCardText,
  BCard,
  BBadge,
  BCol,
  BTextArea,
  BRow,
  BTable,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFromRadioGroup,
  BFormRadio,
  BFormTextarea,
} from "bootstrap-vue";
import List from "@/views/components/info/list";
import BootstrapVueTimeline from "bootstrap-vue-timeline";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from '@validations'
import ValidationErrors from "@/views/components/common/ValidationErrors";


Vue.component("b-timeline", BootstrapVueTimeline);

export default {
  name: "patient_case",
  components: {
    BootstrapVueTimeline,
    vSelect,
    show,
    BFormCheckbox,
    BFormCheckboxGroup,
    BTab,
    BFormTextarea,
    BTextArea,
    BCardText,
    BCard,
    BCol,
    BRow,
    List,
    BBadge,
    BTable,
    BForm,
    BFormGroup,
    BFromRadioGroup,
    BFormInput,
    BFormRadio,
    BButton,
    ValidationErrors,
    ValidationProvider,
    ValidationObserver,
    localize
  },
  data() {
    return {
      case_approvals_fields: [
        {
          key: "approval_type",
          label: "Apprroval Type ",
        },
        {
          key: "service_code",
          label: "Service Code",
        },
        {
          key: "service_name",
          label: "Service Name",
        },
        {
          key: "total_qty_service",
          label: "Total Quantity Service",
        },
        {
          key: "is_approved",
          label: "Status ",
        },
        {
          key: "approval_number",
          label: "Approval Number",
        },
        {
          key: "manual.name",
          label: " Manual / Auto",
        },

        {
          key: "insurance_membership_no",
          label: "Membership ID ",
        },

        {
          key: "approval_date",
          label: " Approval Date",
        },

        {
          key: "account_no",
          label: "Account Number",
        },
      ],
      investigation_fields: [
        {
          key: "tooth_no",
          label: "Tooth No.",
        },
        {
          key: "service_name",
          label: "Name",
        },
        {
          key: "service_code",
          label: "Code",
        },

        {
          key: "visit_no",
          label: "Visit Number",
        },
        {
          key: "date",
          label: "Date",
        },

        {
          key: "dr_name",
          label: "Doctor",
        },
        {
          key: "total_qty_service",
          label: "Total Quantity Service",
        },
      ],
      charge_items_fields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "code",
          label: "Code",
        },
        {
          key: "final_bill_date",
          label: "Discharge Date",
        },
      ],

      diagnosis_fields: [
        {
          key: "description",
          label: "Description ",
        },
        {
          key: "code",
          label: "Code",
        },

        {
          key: "visit_no",
          label: " Visit Number ",
        },
        {
          key: "diagnosis_date",
          label: "Date",
        },
        {
          key: "dr_name",
          label: "Doctor",
        },
      ],
      vitalfields: [
        {
          key: "visit_no",
          label: " Visit Number ",
        },
        {
          key: "vital_date",
          label: "Date ",
        },
        {
          key: "temperature",
          label: "Temprature",
        },
        {
          key: "pulse",
          label: "Pulse",
        },
        {
          key: "systole",
          label: "Systole",
        },
        {
          key: "diastole",
          label: "Diastole",
        },
        {
          key: "weight",
          label: "Weight",
        },
      ],
      chief_fields: [
        {
          key: "description",
          label: "Description ",
        },
        {
          key: "code",
          label: "Code",
        },

        {
          key: "visit_no",
          label: " Visit Number ",
        },
        {
          key: "chief_date",
          label: "Date",
        },
        {
          key: "dr_name",
          label: "Doctor",
        },
      ],
      attending_fields: [
        {
          key: "visit_no",
          label: "Visit Number ",
        },
        {
          key: "dr_name",
          label: "Doctor ",
        },
        {
          key: "description",
          label: "Description",
        },
        // {
        //   key: "time",
        //   label: "Time",
        // },
        {
          key: "att_date",
          label: "Date",
        },
      ],

      surgeries_fields: [
        {
          key: "area",
          label: "Area ",
        },
        {
          key: "abbreviation",
          label: "Abbreviation ",
        },
        // {
        //   key: "code",
        //   label: "Code ",
        // },
        {
          key: "side",
          label: "Side ",
        },
        {
          key: "date",
          label: "Surgery Date",
        },
        {
          key: "code",
          label: "code",
        },
        {
          key: "dr_name",
          label: "Doctor Name",
        },
        {
          key: "surgeon_name",
          label: "Surgery Name",
        },
      ],
      operative_procedures_fields: [
        {
          key: "opr_code",
          label: "Code",
        },
        {
          key: "position_code",
          label: "position code ",
        },
        {
          key: "opr_status",
          label: "status  ",
        },
        {
          key: "position_desc",
          label: "position desc ",
        },
        {
          key: "opr_date",
          label: "Date",
        },
        {
          key: "dr_name",
          label: "Doctor Name",
        },
      ],
      invoice_fields: [
        {
          key: "serial_no",
          label: "Serial Number",
        },
        {
          key: "financial_category",
          label: "Financial Category",
        },
        {
          key: "date",
          label: "date",
        },
      ],

      planfields: [
        {
          key: "area",
          label: "Area ",
        },
        {
          key: "abbreviation",
          label: "Abbreviation ",
        },
        {
          key: "side_name",
          label: "Side  ",
        },
        {
          key: "dr_name",
          label: "Doctor ",
        },
        {
          key: "side_code",
          label: "Location  ",
        },
        {
          key: "code",
          label: "Code  ",
        },
        {
          key: "plan_date",
          label: "Date  ",
        },
      ],
      review_status: [
        {
          id: 0,
          type: "Compliance",
        },
        {
          id: 1,
          type: " Non-Compliance",
        },
        {
          id: 2,
          type: " Missed",
        },
      ],

      case_review: {
        treatment_plan: null,
        treatment_plan_type: null,
        operative_procedure: null,
        operative_procedure_type: null,
        attending_note: null,
        attending_note_type: null,
        investigations: null,
        investigations_type: null,
        diagnosis: null,
        diagnosis_type: null,
        dr_name: null,
        dr_name_type: null,
        chief_complaint: null,
        chief_complaint_type: null,
        consent: null,
        discharge: null,
        operative_report: null,
        notes: null,
      },
      timelineItmems: [
        {
          timestamp: Date.parse("2021-05-28T20:20:46.444Z"),
          title: "Dolore ullamco exercitation commodo",
          content: "Esse dolore consectetur aliqua laboris sunt aliqua do non.",
        },
        {
          timestamp: Date.parse("2021-05-28T20:20:46.444Z"),
          title: "Voluptate pariatur dolore laborum eu",
        },
      ],
    };
  },

  methods: {
    print() {
      console.log(this.case_review.consent);
    },
    move(tabIndex) {
      console.log(tabIndex);
    },

    async changeStatus(status) {
    try {
      // Validate the form fields
      await this.$refs.simpleRules.validate();
      console.log(this.$refs.simpleRules.errors);
      // Check if validation is successful
      if (this.$refs.simpleRules.errors.length === 0) {
        // Proceed with the status change if validation passes
        await this.$store.dispatch("cases/changeStatus", {
          id: this.id,
          query: { status: status },
        });

        // Reset the form or perform other actions after successful status change
        this.init();
      } else {
        // Validation failed, display an error message or handle accordingly
        console.error('Validation failed. Please correct the form errors.');
      }
    } catch (error) {
      // Handle other exceptions or errors that may occur during validation
      console.error('An error occurred during validation:', error);
    }
  },

    init() {
      this.$store.dispatch("cases/get", this.id).then((response) => {
        if (response.data.case_review !== null) {
          this.case_review = response.data.case_review;
          if (this.case_review.consent == 1) {
            this.case_review.consent = true;
          }

          if (this.case_review.discharge == 1) {
            this.case_review.discharge = true;
          }

          if (this.case_review.operative_report == 1) {
            this.case_review.operative_report = true;
          }
        }
      });
    },

    SaveReview() {
      this.case_review.case_id = this.id;
      console.log(this.case_review);
      this.$store
        .dispatch("case_review/put", {
          id: this.review_id,
          query: this.case_review,
        })
        .then((_) => {
          this.$swal({
            icon: "success",
            title: this.$t("Global.Saved"),
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((error) => {
          this.errorsdata = this.handleBackendError(error.response.data.errors);
        });
    },
  },

  computed: {
    timelineItems() {
      let timeline = [];
      let auditor_name = "";
      if (this.patient_case.auditor) {
        auditor_name = this.patient_case.auditor.name;
      }
      this.patient_case.history.forEach((element) =>
        timeline.push({
          title: element.title,
          timestamp: Date.parse(element.timestamp),
          content: "User: " + this.authUser().first_name,
        })
      );
      return timeline;
    },

    ...mapGetters({
      patient_case: "cases/item",
      load: "cases/load",
    }),

    status() {
      if (this.patient_case && this.patient_case.status) {
        return this.patient_case.status.status;
      } else {
        return 0;
      }
    },
    review_id() {
      if (this.$store.getters["cases/item"].case_review !== null) {
        return this.$store.getters["cases/item"].case_review.id;
      } else {
        return null;
      }
    },
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 1px 1px 1px #eee, 1px 1px 1px #e0dcdc;
}

.size-min {
  font-size: 10px;
}

.custom_last_table tbody tr th {
  width: 20%;
}
</style>
